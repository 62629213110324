 import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 441.000000 156.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,156.000000) scale(0.100000,-0.100000)">


<path d="M873 1210 c-265 -5 -267 -6 -317 -74 -20 -26 -21 -42 -24 -399 -4
-465 -4 -469 54 -524 l44 -43 439 0 c469 0 477 1 530 51 49 47 51 61 51 472 0
318 -3 390 -15 418 -21 48 -35 64 -80 88 -44 24 -46 24 -682 11z m265 -194 c3
-3 -9 -6 -26 -6 -18 0 -32 4 -32 9 0 9 48 6 58 -3z m139 7 c-10 -10 -115 -15
-121 -5 -2 4 25 9 62 10 36 1 63 -1 59 -5z m228 -3 c-5 -9 -74 -5 -84 5 -2 3
17 5 43 5 27 0 45 -4 41 -10z m45 0 c0 -5 -7 -10 -16 -10 -8 0 -12 5 -9 10 3
6 10 10 16 10 5 0 9 -4 9 -10z m-818 -7 c-12 -2 -20 -9 -17 -14 4 -5 -4 -5
-19 1 -18 6 -26 6 -26 -1 0 -6 -5 -7 -12 -3 -6 4 -25 11 -42 15 -21 5 -3 7 54
7 47 -1 75 -3 62 -5z m768 -24 c0 -5 -6 -9 -13 -9 -8 0 -14 6 -14 14 0 8 6 12
14 9 7 -3 13 -9 13 -14z m-800 -8 c0 -5 10 -12 23 -14 21 -4 21 -4 1 -6 -12 0
-27 6 -34 14 -9 10 -9 15 -1 15 6 0 11 -4 11 -9z m780 -21 c0 -5 -5 -10 -11
-10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z m-283 -6 c-3 -3 -12 -4
-19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m-441 -16 c-9 -5 -23 -5 -32 0 -12 6
-7 9 16 9 23 0 28 -3 16 -9z m482 -10 c17 -17 15 -25 -8 -31 -11 -3 -20 -1
-20 4 0 5 8 9 17 9 15 0 14 2 -3 15 -10 8 -14 15 -8 15 5 0 15 -5 22 -12z
m-368 -60 c0 -17 -10 -6 -25 25 l-16 32 20 -25 c11 -14 21 -28 21 -32z m-142
8 c-1 -14 -6 -22 -10 -20 -12 7 -10 44 2 44 5 0 9 -11 8 -24z m89 -1 c0 -8 -4
-15 -9 -15 -4 0 -8 7 -8 15 0 8 4 15 8 15 5 0 9 -7 9 -15z m453 -20 c-9 -10
-7 -14 7 -18 16 -4 16 -5 1 -6 -10 0 -22 7 -27 17 -8 14 -5 18 11 19 15 2 17
-1 8 -12z m-370 -15 c8 -15 8 -18 -3 -14 -8 3 -14 11 -14 19 0 20 4 19 17 -5z
m-172 -135 c1 -69 -1 -125 -6 -125 -4 0 -9 53 -9 117 -2 100 1 133 10 133 1 0
3 -56 5 -125z m573 88 c-10 -17 -10 -16 -11 3 0 23 13 41 18 25 2 -6 -1 -18
-7 -28z m-337 -29 c15 -19 22 -32 15 -28 -17 7 -64 64 -52 63 4 0 21 -16 37
-35z m381 -4 c-3 -5 -11 -10 -16 -10 -6 0 -7 5 -4 10 3 6 11 10 16 10 6 0 7
-4 4 -10z m-385 -40 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6
0 10 -4 10 -10z m330 0 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10
10 6 0 10 -4 10 -10z m75 -9 c-3 -6 -11 -8 -17 -5 -6 4 -5 9 3 15 16 10 23 4
14 -10z m-370 -11 c-3 -5 -11 -10 -16 -10 -6 0 -7 5 -4 10 3 6 11 10 16 10 6
0 7 -4 4 -10z m275 -3 c0 -2 -10 -12 -21 -23 -22 -19 -22 -19 -8 4 12 19 29
30 29 19z m-82 -37 c-1 -16 -6 -30 -11 -30 -5 0 -6 7 -3 16 3 9 6 22 6 30 0 8
2 14 5 14 3 0 4 -13 3 -30z m208 20 c8 0 14 -5 14 -11 0 -5 -4 -7 -10 -4 -17
11 -11 -11 10 -35 11 -13 20 -19 20 -14 0 6 -6 16 -12 23 -11 10 -10 11 5 6 9
-4 17 -17 17 -30 0 -13 3 -30 6 -39 3 -9 2 -16 -2 -16 -9 1 -65 87 -61 95 1 2
-7 11 -18 20 -18 15 -18 16 -2 11 10 -3 25 -6 33 -6z m-323 -71 c-5 -17 5 -24
30 -20 9 1 9 -13 1 -22 -4 -3 -13 1 -21 9 -8 8 -19 11 -25 8 -7 -4 -8 -2 -4 4
4 6 2 20 -3 31 -9 16 -8 18 8 14 13 -3 17 -11 14 -24z m127 -3 c0 -2 -7 -7
-16 -10 -8 -3 -12 -2 -9 4 6 10 25 14 25 6z m276 -33 c-7 -7 -26 7 -26 19 0 6
6 6 15 -2 9 -7 13 -15 11 -17z m-749 -35 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3
-3 4 -12 1 -19z m780 0 c10 -9 32 -18 48 -20 17 -3 0 -5 -38 -5 -37 -1 -65 3
-62 7 3 5 1 11 -5 15 -16 10 -2 25 20 22 11 -2 27 -10 37 -19z m-407 -12 c0
-2 -8 -10 -17 -17 -16 -13 -17 -12 -4 4 13 16 21 21 21 13z m-372 -42 c-3 -14
-5 -12 -10 9 -3 19 -2 25 4 19 6 -6 8 -18 6 -28z m482 26 c0 -5 -4 -10 -10
-10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m218 3 c-10 -2 -26
-2 -35 0 -10 3 -2 5 17 5 19 0 27 -2 18 -5z m-238 -53 c0 -5 -5 -10 -11 -10
-5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z m-450 -82 c8 -3 -16 -5 -55
-5 -38 0 -59 2 -45 4 13 3 33 11 44 19 16 11 21 11 30 0 6 -8 18 -16 26 -18z
m80 -4 c0 -2 -11 -4 -25 -4 -14 0 -25 4 -25 9 0 4 11 6 25 3 14 -2 25 -6 25
-8z"/>
<path d="M688 383 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M2967 904 c-4 -4 -7 -65 -7 -136 0 -121 1 -128 20 -128 18 0 20 7 20
54 0 78 13 106 49 106 36 0 41 -13 41 -96 0 -57 2 -64 20 -64 18 0 20 7 20 80
0 69 -3 82 -22 100 -23 21 -50 23 -90 6 -14 -6 -17 -1 -20 35 -3 40 -16 58
-31 43z"/>
<path d="M1745 888 c-3 -8 -4 -68 -3 -133 3 -105 5 -120 21 -123 15 -3 17 7
17 95 l1 98 24 -30 c14 -16 44 -61 67 -98 33 -51 48 -67 63 -65 19 3 20 11 20
133 0 109 -2 130 -15 130 -12 0 -16 -18 -18 -92 -1 -51 -6 -93 -10 -93 -4 0
-34 42 -67 93 -57 88 -89 115 -100 85z"/>
<path d="M2530 895 c0 -6 42 -67 51 -75 3 -3 11 -15 18 -27 10 -19 5 -30 -34
-87 -43 -64 -44 -66 -21 -66 18 0 32 12 53 45 15 25 33 45 39 45 5 0 23 -20
38 -45 21 -33 35 -45 53 -45 17 0 22 4 17 13 -19 33 -69 103 -76 108 -5 3 9
33 32 66 22 33 40 64 40 67 0 15 -44 0 -54 -18 -6 -11 -20 -30 -31 -43 l-21
-23 -32 45 c-20 28 -39 45 -52 45 -11 0 -20 -2 -20 -5z"/>
<path d="M2382 829 c-41 -16 -55 -39 -60 -94 -4 -47 -1 -57 21 -79 43 -43 120
-31 145 21 20 45 15 95 -13 126 -14 15 -30 27 -35 27 -4 0 -14 2 -22 4 -7 3
-23 1 -36 -5z m54 -37 c17 -11 26 -49 19 -82 -16 -71 -95 -55 -95 20 0 55 39
87 76 62z"/>
<path d="M2817 824 c-51 -28 -64 -113 -24 -159 41 -48 137 -29 137 27 0 24
-27 23 -35 -2 -4 -14 -15 -20 -34 -20 -36 0 -55 23 -55 65 0 18 6 40 13 49 17
20 70 21 77 1 7 -18 34 -20 34 -2 0 18 -12 32 -37 46 -28 14 -42 14 -76 -5z"/>
<path d="M3190 813 c-15 -19 -18 -29 -10 -37 8 -8 15 -6 25 8 14 19 67 27 81
13 14 -13 -7 -37 -31 -37 -66 0 -109 -53 -80 -97 14 -20 22 -22 95 -20 82 1
89 4 68 25 -6 6 -12 41 -12 78 -1 76 -16 94 -77 94 -29 0 -42 -6 -59 -27z
m100 -97 c0 -22 -29 -46 -56 -46 -32 0 -42 27 -18 45 25 19 74 19 74 1z"/>
<path d="M3375 828 c-3 -7 -4 -51 -2 -98 2 -68 5 -85 17 -85 11 0 16 15 18 65
4 75 14 92 56 88 l31 -3 5 -75 c4 -58 8 -75 20 -75 12 0 15 16 15 84 -1 70 -4
85 -20 97 -23 17 -67 18 -83 2 -9 -9 -15 -9 -24 0 -15 15 -27 15 -33 0z"/>
<path d="M3609 827 c-46 -36 -53 -119 -13 -162 23 -25 30 -27 70 -21 50 6 61
-11 22 -38 -28 -20 -52 -20 -68 -1 -16 19 -40 19 -40 1 0 -21 40 -46 73 -46
70 0 97 51 97 187 0 81 -9 104 -33 84 -8 -6 -18 -6 -27 -1 -21 13 -62 12 -81
-3z m95 -65 c13 -48 -7 -82 -50 -82 -29 0 -34 4 -39 33 -4 18 -2 45 4 60 9 24
16 28 43 25 27 -2 34 -9 42 -36z"/>
<path d="M3812 820 c-41 -39 -42 -129 -1 -162 31 -26 91 -23 117 5 39 42 24
64 -18 27 -30 -26 -65 -25 -84 2 -9 12 -16 26 -16 30 0 5 32 8 70 8 l70 0 0
34 c0 65 -89 101 -138 56z m79 -20 c36 -20 23 -40 -26 -40 -50 0 -57 11 -23
34 27 19 24 19 49 6z"/>
<path d="M2043 824 c-7 -3 -22 -21 -33 -40 -43 -69 0 -154 77 -154 33 0 83 36
83 60 0 17 -26 11 -45 -10 -30 -34 -76 -25 -89 18 -7 21 -5 22 63 22 l71 0 0
29 c0 41 -41 81 -82 80 -18 0 -38 -3 -45 -5z m71 -40 c26 -25 19 -34 -29 -34
-25 0 -45 4 -45 9 0 14 30 41 45 41 7 0 21 -7 29 -16z"/>
<path d="M2212 735 c3 -108 20 -131 28 -39 5 63 18 89 51 99 10 4 19 13 19 21
0 17 -31 18 -49 2 -11 -10 -14 -10 -18 0 -3 6 -11 12 -19 12 -12 0 -14 -17
-12 -95z"/>
<path d="M1763 495 c0 -33 2 -45 4 -27 2 18 2 45 0 60 -2 15 -4 0 -4 -33z"/>
<path d="M2607 543 c-11 -10 -8 -93 3 -93 6 0 10 23 10 50 0 51 -1 55 -13 43z"/>
<path d="M2890 540 c0 -5 -9 -10 -20 -10 -23 0 -40 -33 -32 -62 3 -12 14 -18
33 -18 28 0 29 2 29 50 0 28 -2 50 -5 50 -3 0 -5 -4 -5 -10z m-7 -44 c7 -19
-18 -42 -28 -26 -8 13 1 40 14 40 5 0 11 -6 14 -14z"/>
<path d="M1989 523 c-3 -23 4 -69 12 -78 6 -6 7 2 3 22 -4 18 -2 33 5 37 6 4
9 12 6 17 -7 11 -25 12 -26 2z"/>
<path d="M2235 513 c-27 -26 -15 -72 19 -74 19 -1 19 -2 -4 -9 -22 -6 -23 -8
-7 -15 29 -12 47 12 45 60 -2 48 -26 65 -53 38z m33 -30 c-5 -25 -28 -28 -28
-4 0 12 6 21 16 21 9 0 14 -7 12 -17z"/>
<path d="M2477 523 c-13 -13 -7 -21 13 -16 29 8 25 -6 -5 -17 -24 -9 -34 -34
-17 -43 4 -3 19 -3 34 0 23 4 26 9 23 36 -5 36 -31 58 -48 40z m28 -53 c-3 -5
-10 -10 -16 -10 -5 0 -9 5 -9 10 0 6 7 10 16 10 8 0 12 -4 9 -10z"/>
<path d="M2706 508 c-20 -28 -4 -58 29 -58 14 0 25 4 25 9 0 4 -12 6 -26 3
-20 -4 -25 -1 -22 9 3 8 17 16 33 19 27 5 28 6 11 23 -23 22 -32 22 -50 -5z"/>
<path d="M3020 515 c-10 -12 -8 -16 14 -25 30 -11 36 -35 6 -27 -11 3 -20 1
-20 -4 0 -15 48 -10 54 6 4 10 -3 20 -19 27 -30 13 -34 32 -5 23 11 -4 20 -2
20 4 0 16 -36 13 -50 -4z"/>
<path d="M3149 506 c-9 -20 -9 -29 1 -41 14 -17 46 -20 55 -5 3 5 -2 9 -12 7
-25 -3 -28 17 -3 21 28 5 22 36 -8 40 -15 2 -25 -4 -33 -22z m36 4 c3 -5 1
-10 -4 -10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z"/>
<path d="M3291 488 c1 -41 1 -41 8 -12 4 17 11 36 15 42 4 7 1 12 -8 12 -12 0
-16 -10 -15 -42z"/>
<path d="M3380 522 c0 -5 7 -23 16 -41 12 -25 19 -30 28 -21 8 8 7 14 -6 21
-10 6 -18 16 -18 24 0 8 -4 17 -10 20 -5 3 -10 2 -10 -3z"/>
<path d="M3433 515 c-3 -9 -3 -18 -1 -21 3 -3 8 4 11 16 6 23 -1 27 -10 5z"/>
<path d="M3523 495 c0 -22 2 -30 4 -17 2 12 2 30 0 40 -3 9 -5 -1 -4 -23z"/>
<path d="M3615 521 c-23 -39 1 -79 39 -65 25 10 18 22 -9 16 -20 -3 -25 0 -25
16 0 28 17 37 37 20 14 -11 16 -11 11 2 -6 18 -43 26 -53 11z"/>
<path d="M3747 524 c-4 -4 -7 -20 -7 -35 0 -31 34 -49 53 -30 9 9 5 11 -12 9
-13 -2 -26 3 -28 10 -3 7 5 12 22 12 20 0 26 4 23 18 -3 17 -38 29 -51 16z
m38 -14 c3 -5 -1 -10 -9 -10 -9 0 -16 5 -16 10 0 6 4 10 9 10 6 0 13 -4 16
-10z"/>
<path d="M3885 520 c-8 -14 3 -30 21 -30 8 0 14 -7 14 -16 0 -8 5 -12 10 -9
14 9 -7 35 -26 35 -8 0 -14 5 -14 11 0 6 9 9 20 6 11 -3 18 -1 14 4 -7 12 -31
11 -39 -1z"/>
<path d="M1850 479 c0 -22 4 -38 9 -35 5 3 7 14 4 24 -2 10 3 24 11 31 17 14
19 11 29 -34 3 -11 5 -3 6 18 1 35 -1 37 -29 37 -28 0 -30 -2 -30 -41z"/>
<path d="M2085 500 c-17 -54 36 -85 56 -33 13 35 5 53 -25 53 -16 0 -27 -7
-31 -20z m40 -30 c3 -5 -1 -10 -10 -10 -9 0 -13 5 -10 10 3 6 8 10 10 10 2 0
7 -4 10 -10z"/>
<path d="M2360 485 c0 -19 5 -35 10 -35 6 0 10 13 10 29 0 16 6 32 13 34 6 3
2 6 -10 6 -20 1 -23 -4 -23 -34z"/>
<path d="M3878 463 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
</g>
</svg>


 

    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}